import React from "react";

const UserRole = ({dataRoom}) => {
  const renderRoleSeat = (type) => {
    switch (type) {
      case 1:
        return (
          <img
            src="/images/login/10.png"
            className={`lg:w-[35px] 2xl:w-[55px] h-auto`}
          />
        );
      case 2:
      case "SB":
        return (
          <img
            src="/images/login/11.png"
            className={`lg:w-[35px] 2xl:w-[55px] h-auto`}
          />
        );
      case 3:
      case "BB":
        return (
          <img
            src="/images/login/12.png"
            className={`lg:w-[35px] 2xl:w-[55px] h-auto`}
          />
        );
      default:
        break;
    }
  };
  return (
    // <div>UserRole</div>
    <>{renderRoleSeat(dataRoom?.PositionType ?? null)}</>
  );
};

export default UserRole;
