import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";

const ShowCard = () => {
  const { cardMain } = useSelector((state) => state.room);

  const renderCard = (index) => {
    return (
      <div className="lg:w-[55px] 2xl:w-[75px] lg:h-[80px] 2xl:h-[110px] border-dashed border-[#efdb78] border-[1px] rounded-[10px] flex items-center justify-center">
        {!_.isEmpty(cardMain) && cardMain[index] && (
          <img
            src={`/images/52card/${cardMain[index].CardCode}.png`}
            className="lg:w-[50px] 2xl:w-[65px] lg:h-[70px] 2xl:h-[100px]"
          />
        )}
      </div>
    );
  };

  return (
    <div className="lg:min-w-[250px] w-fit h-[100px] lg:h-[120px] 2xl:min-w-[460px] 2xl:h-[180px] bg-no-repeat lg:bg-contain 2xl:bg-contain lg:text-[30px] 2xl:text-[40px] bg-[#1a3d1b] lg:ml-[20px] 2xl:mx-[100px] rounded-[20px] border-[7px] border-solid border-[#e0b851] flex items-center lg:mx-[0px]">
      <div className="lg:p-[5px] 2xl:p-[10px] h-full flex flex-col justify-between box-border">
        <h5 className="border-b-[3px] border-t-[0px] border-r-[0px] border-l-[0px] border-solid border-[#efdb78] text-center text-[#efdb78] lg:text-[14px] 2xl:text-[25px]">
          FLOP
        </h5>
        <div className="grid grid-cols-3 lg:gap-[5px] 2xl:gap-[10px]">
          {renderCard(0)}
          {renderCard(1)}
          {renderCard(2)}
        </div>
      </div>
      <div className="lg:p-[5px] 2xl:p-[10px] h-full flex flex-col justify-between box-border">
        <h5 className="border-b-[3px] border-t-[0px] border-r-[0px] border-l-[0px] border-solid border-[#efdb78] text-center text-[#efdb78] lg:text-[14px] 2xl:text-[25px]">
          TURN
        </h5>
        {renderCard(3)}
      </div>
      <div className="lg:p-[5px] 2xl:p-[10px] h-full flex flex-col justify-between box-border">
        <h5 className="border-b-[3px] border-t-[0px] border-r-[0px] border-l-[0px] border-solid border-[#efdb78] text-center text-[#efdb78] lg:text-[14px] 2xl:text-[25px]">
          RIVER
        </h5>
        {renderCard(4)}
      </div>
    </div>
  );
};

export default ShowCard;
