import React, { useMemo } from 'react'

const SeatInfoPlayer = ({data}) => {

  const accountBalance = useMemo(()=> {
    if(data?.Player?.TotalAmount) {
      return data?.Player?.TotalAmount - data?.Player?.PriceOnTable - data?.TotalBet
    } return 0
  }, [data?.Player?.TotalAmount, data?.Player?.uid]); 

  return (
    <div className='p-[10px] grid grid-cols-1 lg:gap-[5px] 2xl:gap-[8px] bg-[#1a3d1b] rounded-[7px] border-[7px] border-solid border-[#e0b851]'>
        <div className='flex items-center justify-start'>
            <h5 className='text-[#efdb78] text-[10px] lg:text-[12px] 2xl:text-[15px] lg:mr-[7px] 2xl:mr-[10px]'>Nickname: </h5>
            <span className="text-[10px] lg:text-[13px] 2xl:text-[15px] text-white font-bold">{data?.Player?.nickName}</span>
        </div>
        <div className='flex items-center justify-start'>
            <h5 className='text-[#efdb78] text-[10px] lg:text-[12px] 2xl:text-[15px] lg:mr-[7px] 2xl:mr-[10px]'>Balance: </h5>
            <span className="text-hightlight text-[10px] lg:text-[13px] 2xl:text-[15px]">${data?.Player?.TotalMoney?.toLocaleString()}</span>
        </div>
        <div className='flex items-center justify-start'>
            <h5 className='text-[#efdb78] text-[10px] lg:text-[12px] 2xl:text-[15px] lg:mr-[7px] 2xl:mr-[10px]'>Buy-in: </h5>
            <span className="text-hightlight text-[10px] lg:text-[13px] 2xl:text-[15px]">${data?.Player?.PriceOnTable?.toLocaleString()}</span>
        </div>
    </div>
  )
}

export default SeatInfoPlayer