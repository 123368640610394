import React from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Input } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useLogin } from "./hook/useLogin";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { loginSuccess } from "redux/action/homeAction";

const Login = () => {
  const { mutate, isLoading } = useLogin();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const method = useForm({
    mode: "onSubmit",
    defaultValues: {
      userName: "",
      password: "",
    },
  });

  const { handleSubmit, control } = method;

  const handleSubmitForm = async (data) => {
    await mutate({
      ...data,
      onSuccess: (msg) => {
        localStorage.setItem("jwt", msg.data.token.token);
        // socket.connect();
        dispatch(loginSuccess(msg.data.user));
        toast.dismiss();
        toast.success("Login success");
        if (msg?.data?.user) {
          setTimeout(() => {
            navigate("/", { replace: true });
          }, 1000);
        }
      },
      onError: (msg) => {
        toast.dismiss();
        toast.error(msg.message);
      },
    });
  };

  const handleSignUp = () => {
    navigate("/signup");
  };

  return (
    <div
      className="w-full h-full bg-cover bg-no-repeat"
      style={{ backgroundImage: "url('/images/login/BackgroundLogin.png')" }}
    >
      <div className="flex flex-col items-center justify-center lg:justify-top w-full h-full">
        <div className="bg-[#de1f22] rounded-[20px] xl:rounded-[40px] flex flex-col items-center w-[90vw] md:w-[60vw] xl:w-[50vw] 2xl:w-[40vw]  relative z-[4] formLogin">
          <img
            src="/images/login/card.png"
            className="absolute top-[-50px] left-[-50px] z-[-1] w-[150px] lg:w-[250px] 2xl:w-[350px]"
            style={{ transform: "translate(-15%, -50%)" }}
          />
          <img
            src="/images/login/coin.png"
            className="absolute bottom-[-50px] right-[-50px] w-[200px] lg:w-[300px] 2xl:w-[450px]"
            style={{ transform: "translate(20%, 30%)" }}
          />
          <FormProvider {...method}>
            <form
              onSubmit={handleSubmit(handleSubmitForm)}
              className="bg-[#de1f22] rounded-[20px] xl:rounded-[40px] p-[10px] flex flex-col items-center w-full border-dotted border-[10px] xl:border-[25px] border-[white]"
            >
              <div
                className="rounded-[20px] xl:rounded-[40px] p-[10px_20px_20px_20px] lg:p-[10px_30px_30px_30px] xl:p-[20px_50px_50px_50px] 2xl:p-[20px_60px_60px_60px] flex flex-col items-center "
                style={{
                  backgroundImage:
                    "linear-gradient(to right, #1a3d1b, #112115)",
                  width: "-webkit-fill-available",
                }}
              >
                <img
                  src="/images/login/LivegameText.png"
                  className="hidden lg:block lg:w-[280px] xl:w-[450px] 2xl:w-[600px] h-auto object-fit object-left"
                />
                <div className="lg:grid lg:grid-cols-2 lg:gap-[15px] 2xl:gap-[20px] h-fit w-full mt-[20px]">
                  <div className="col-span-1">
                    <div className="grid grid-cols-1 gap-[10px] lg:gap-[10px] 2xl:gap-[25px]">
                      <Controller
                        name="userName"
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            placeholder="ID(아이디)"
                            className="lg:p-[10px] 2xl:p-[15px]"
                          />
                        )}
                      />
                      <Controller
                        name="password"
                        control={control}
                        render={({ field }) => (
                          <Input.Password
                            {...field}
                            placeholder="PW(비밀번호)"
                            className="lg:p-[10px] 2xl:p-[15px]"
                            iconRender={(visible) =>
                              visible ? (
                                <EyeTwoTone />
                              ) : (
                                <EyeInvisibleOutlined />
                              )
                            }
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-span-1 flex flex-row lg:flex-col justify-between mt-[20px] lg:mt-[0px]">
                    <button
                      type="submit"
                      className="cursor-pointer w-[150px] lg:w-[170px] 2xl:w-[200px] p-[10px] lg:p-[15px_7px] 2xl:p-[15px_10px] rounded-[30px] border-none outline-none m-[0_auto] bg-[#cf3c4b] text-[#fff] font-bold flex items-center justify-center"
                    >
                      Login (로그인)
                    </button>
                    <button
                      type="button"
                      className="cursor-pointer  w-[150px] p-[10px] lg:w-[170px] 2xl:w-[200px] lg:p-[15px_7px] 2xl:p-[15px_10px] rounded-[30px] border-none outline-none m-[0_auto] bg-[#2d9c68] text-[#fff] font-bold flex items-center justify-center"
                      onClick={handleSignUp}
                    >
                      Sign up (등록)
                    </button>
                  </div>
                  {/* <div className="hidden lg:block col-span-3 relative">
                <img
                  src="/images/cardlogin.png"
                  className="absolute top-[40%] left-[35%] lg:w-[350px] 2xl:w-[450px] z-[3]"
                  style={{ transform: "translate(-20%, -40%) rotate(18deg)" }}
                />
                <img
                  src="/images/coinlogin.png"
                  className="absolute top-[40%] left-[10%] lg:w-[200px] 2xl:w-[300px] z-[4]"
                />
              </div> */}
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </div>
  );
};

export default Login;
