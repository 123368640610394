import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { CustomPopup } from "share/components";
import { useDispatch, useSelector } from "react-redux";
import { closePopup } from "redux/action/custom-popup/customPopupAction";
import { POPUP_ID } from "share/constantShared";
import { updateSeatOnRoom } from "redux/action/roomAction";

const BuyInPopupV2 = ({ id, handleTakeSeat, socket }) => {
  const { userInfo } = useSelector((state) => state.home);

  const { mySeat, betReverved } = useSelector((state) => state.room);

  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      price: 100,
    },
    resolver: yupResolver(
      yup.object().shape({
        price: yup
          .number()
          .required()
          .min(100, "min")
          .max(userInfo?.accountBalance, "max"),
      })
    ),
  });

  const handleCancel = () => {
    socket.send(
      JSON.stringify({
        FunctionCode: "LeaveSeat",
        FFunctionCode: "",
        Data: {
          Player: {
            userName: userInfo?.userName,
            nickName: userInfo?.nickName,
            uid: userInfo?.uid,
            PlayerType: userInfo?.roleId,
            TotalAmount: userInfo?.accountBalance,
            ipUser: "",
            PriceOnTable: 0,
          },
          SeatPosCode: mySeat?.PositionCode
        },
        Info: null,
        OData: null,
      })
    );
    dispatch(updateSeatOnRoom(mySeat?.PositionCode));
    dispatch(closePopup({ id }));
  };

  const handleOk = (dataForm) => {
    handleTakeSeat(dataForm);
    dispatch(closePopup({ id }));
  };

  const handleChangeBuyIn = (type) => {
    switch (type) {
      case "plus":
        return setValue("price", userInfo?.accountBalance ?? 100);
      case "minas":
        return setValue("price", 100);
      default:
        return;
    }
  };

  return (
    <CustomPopup
      id={POPUP_ID.BUY_IN}
      style={{ padding: 0 }}
      closeIcon={false}
      className="no-padding"
    >
      <form
        className="flex flex-col items-center justify-between bg-[#1a3d1b] lg:w-[350px] 2xl:min-w-[450px] w-fit lg:p-[5px] 2xl:p-[10px] rounded-[20px] border-[7px] border-solid border-[#e0b851]"
        onSubmit={handleSubmit(handleOk)}
      >
        <h4
          className="text-[#e0b851] lg:text-[20px] 2xl:text-[30px] border-solid border-[#e0b851] lg:mb-[15px] 2xl:mb-[20px]"
          style={{ borderWidth: "0px 0px 4px 0px" }}
        >
          BUY-IN
        </h4>
        <div className="flex justify-between items-center lg:p-[7px] 2xl:p-[10px] w-[90%] bg-[#fffbe0] rounded-[10px] lg:mb-[15px] 2xl:mb-[20px] font-bold lg:text-[15px] 2xl:text-[20px] text-[#441d00]">
          <span>BALANCE</span>
          <span>
            $
            {mySeat?.Player?.TotalAmmount > 0
              ? mySeat?.Player?.TotalAmmount?.toLocaleString()
              : userInfo?.accountBalance?.toLocaleString()}
          </span>
        </div>
        <div className="flex justify-between items-end p-[10px] w-[90%] mb-[20px]">
          <button
            type="button"
            className="p-[10px_20px] rounded-[15px] outline-none border-none"
            onClick={() => handleChangeBuyIn("minas")}
          >
            <span className="lg:text-[14px] 2xl:text-[17px] text-[#441d00] font-bold">MIN</span>
          </button>
          <div className="flex items-center justify-between bg-[#fffbe0]   w-[220px] rounded-[10px] p-[10px] mx-[10px] text-[#441d00]">
            <img src="/images/livegame.png" className="w-[40px] h-[40px]" />
            <div
              className="flex items-center text-[20px] font-bold border-solid border-[1px] border-[#a7a3a3] w-[70%] rounded-[10px] p-[5px]"
              style={{
                borderColor: `${errors.price ? "red" : "#a7a3a3"}`,
              }}
            >
              <span style={{ transform: "translateY(-2px)" }}>$</span>
              <Controller
                control={control}
                name="price"
                render={({ field }) => {
                  return (
                    <input
                      className="lg:text-[16px] 2xl:text-[20px] w-[90%] outline-none border-none ml-[5px] bg-[#fffbe0]"
                      {...field}
                    />
                  );
                }}
              />
            </div>
          </div>
          <button
            type="button"
            className="p-[10px_20px] rounded-[15px] outline-none border-none"
            onClick={() => handleChangeBuyIn("plus")}
          >
            <span className="lg:text-[14px] 2xl:text-[17px] text-[#441d00] font-bold">MAX</span>
          </button>
        </div>
        <div className="flex justify-between items-center p-[10px] w-[90%]">
          <button
            type="button"
            onClick={handleCancel}
            className="cursor-pointer lg:w-[120px] 2xl:w-[150px] p-[12px_10px] rounded-[20px] border-none outline-none bg-[#FFDF00]  flex items-center justify-center lg:text-[14px] 2xl:text-[17px] font-bold"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="cursor-pointer lg:w-[120px] 2xl:w-[150px] p-[12px_10px] rounded-[20px] border-none outline-none  bg-[#C83737] text-[#fff] flex items-center justify-center lg:text-[14px] 2xl:text-[17px] font-bold"
          >
            OK
          </button>
        </div>
      </form>
    </CustomPopup>
  );
};

export default BuyInPopupV2;
