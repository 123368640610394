import React, { useMemo } from "react";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import Home from "main/user/home";
import RoomMain from "main/user/room";
import SignUp from "main/user/signup";
import Login from "main/user/login";
import LayoutAdminMaster from "fuse/layout-admin/layout-master";
import CreateRoom from "main/admin/createRoom";
import RoomMgt from "main/admin/roomMgt";
import { useSelector } from "react-redux";
import { decodeToken } from "share/function";
import Page404 from "main/user/page404/Page404";
import LayoutUserMaster from "fuse/layout-user/layout-master";
import UserManagement from "main/admin/userMgt";
import RegisterUser from "main/admin/registerUser";
import UserLogMgt from "main/admin/userLogMgt";

const MainRouter = () => {
  const jwtDecode = localStorage.getItem("jwt");

  const userInfo = useMemo(() => {
    return decodeToken(jwtDecode);
  }, [jwtDecode]);

  return (
    // <div>
      <Routes>
        {userInfo?.roleId == 2 ? (
          <>
            <Route path="/" element={<LayoutAdminMaster />}>
              <Route path="createRoom" element={<CreateRoom />} />
              <Route path="roomMgt" element={<RoomMgt />} />
              <Route path="userMgt" element={<UserManagement />} />
              <Route path="registerUser" element={<RegisterUser />} />
              <Route path="userLogsMgt" element={<UserLogMgt/>} />
              <Route index element={<RoomMgt />} />
            </Route>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Page404 />} />
          </>
        ) : userInfo?.roleId == 1 ? (
          <>
            <Route path="/" element={<LayoutUserMaster/>}>
              <Route index  element={<Home />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/room" element={<RoomMain />} />
            </Route>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Page404 />} />
            <Route path="/signup" element={<SignUp />} />
          </>
        ) : (
          <>
            <Route path="*" element={<Login />} />{" "}
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
          </>
        )}
      </Routes>
    // </div>
  );
};

export default MainRouter;
