import React from "react";
import { useSelector } from "react-redux";

const Chat = ({ onClick, data }) => {
  const { openChat, newChat } = useSelector((state) => state.room);
  return (
    <button
      className="bg-transparent outline-none border-none w-[18px] h-[18px] lg:w-[20px] lg:h-[20px] 2xl:w-[25px] 2xl:h-[25px] relative"
      onClick={onClick}
    >
      <img src={data.icon} className="w-full h-full object-cover" />
      {(!openChat && newChat) ? (
        <div
          className="absolute top-[-10px] right-[0px] bg-[red] text-white w-[15px] h-[15px] lg:w-[18px] lg:h-[18px] flex items-center justify-center rounded-[50%] text-[11px]"
          style={{ transform: "translateX(50%)" }}
        >
          {newChat}
        </div>
      ) : ""}
    </button>
  );
};

export default Chat;
