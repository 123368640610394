import React from "react";
import ToolbarItem from "./components/toolbarItem";
import { fakeDataToolbarRoom } from "../../constant";
import _ from "lodash";

const ToolbarRoom = ({ handleRefresh, handleChangeCameraStream }) => {
  const renderToolbar = () => {
    if (_.isEmpty(fakeDataToolbarRoom)) return;
    return fakeDataToolbarRoom?.map((toolbar, index) => {
      return (
        <ToolbarItem
          data={toolbar}
          key={index}
          handleRefresh={handleRefresh}
          handleChangeCameraStream={handleChangeCameraStream}
        />
      );
    });
  };
  return (
    <div
      className="grid grid-cols-6 gap-[15px] lg:gap-[30px]  p-[5px_15px] rounded-[25px] border-[4px] border-solid border-[#e0b851] box-border h-fit bg-[#fffbe0]"
      // style={{ backgroundColor: "rgba(119,119,119,0.7)" }}
    >
      {renderToolbar()}
    </div>
  );
};

export default ToolbarRoom;
