import React from "react";

const Check = ({ handleCheck }) => {
  return (
    <button
      type="button"
      onClick={handleCheck}
      className="cursor-pointer lg:w-[70px] lg:p-[7px_5px] 2xl:w-[100px] 2xl:p-[12px_10px] rounded-[20px] border-[4px] border-solid border-[#e0b851] bg-[#1a3d1b]  flex items-center justify-center lg:text-[12px] 2xl:text-[16px] font-bold text-[#efdb78]"
    >
      
      CHECK
    </button>
  );
};

export default Check;
