import React from "react";
import SoundSetting from "../soundSetting/SoundSetting";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { openPopup } from "redux/action/custom-popup/customPopupAction";
import { POPUP_ID } from "share/constantShared";
import { handleChatOpen } from "share/function";
import Help from "../help/Help";
import Chat from "../chat/Chat";
import { updateChat } from "redux/action/roomAction";

const ToolbarItem = ({ data, handleRefresh, handleChangeCameraStream }) => {
  const dispatch = useDispatch();

  const toggleEmoji = () => {
    dispatch(openPopup({ id: POPUP_ID.EMOJI }));
  };

  const handleOnClick = (option) => {
    switch (option.type) {
      case "emoji": {
        toggleEmoji();
        break;
      }
      // case "mutate": {
      //   dispatch(
      //     updateOptionToolbar({
      //       mutate: true,
      //     })
      //   );
      //   break;
      // }
      case "chat": {
        dispatch(updateChat(true))
        handleChatOpen(true)
        break;
      }
      case "refresh": {
        handleRefresh()
        break;
      }
      case "changeCamera": {
        handleChangeCameraStream()
        break;
      }
      case "help": {

        break;
      }
      default: {
        return toast.success("Chức năng đang phát triển!");
      }
    }
  };

  const renderToolBarItem = () => {
    switch (data.type) {
      case "soundSetting": {
        return <SoundSetting data={data} />;
      }
      case "help": {
        return <Help data={data} />;
      }
      case "chat": {
        return <Chat data={data} onClick={()=>handleOnClick(data)}/>
      }
      default:
        return (
          <button
            className="bg-transparent outline-none border-none w-[18px] h-[18px] lg:w-[20px] lg:h-[20px] 2xl:w-[25px] 2xl:h-[25px]"
            onClick={() => handleOnClick(data)}
          >
            <img src={data.icon} className="w-full h-full object-cover" />
          </button>
        );
    }
  };

  return <>{renderToolBarItem()}</>;
};

export default ToolbarItem;
