import { Modal } from "antd";
import _ from "lodash";
import React from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { closePopup } from "redux/action/custom-popup/customPopupAction";
import { CustomPopup } from "share/components";
import { POPUP_ID } from "share/constantShared";

const EmojiBoard = ({ open, handleCancel, socket }) => {
  const { mySeat, userInfo } = useSelector((state) => state.room);
  const dispatch = useDispatch();
  const handleChoose = (data) => {
    if (!_.isEmpty(mySeat)) {
      socket.send(
        JSON.stringify({
          FunctionCode: "SendEmotion",
          FFunctionCode: "",
          Data: {
            Player: {
              userName: userInfo.userName,
              uid: userInfo.uid,
              PlayerType: userInfo?.roleId,
              nickName: userInfo.nickName,
            },
            SeatPosCode: mySeat.PositionCode,
            EmotionId: data 
          },
        })
      );
      dispatch(closePopup({ id: POPUP_ID.EMOJI }))
    } else {
      toast.error('Ngồi chơi mới cho sài cái này')
    }
  };

  const renderEmoji = () => {
    return ["", "", "", "", "", "", "", "", "", "", "", "", "", ""].map(
      (emoji, index) => {
        return (
          <img
            onClick={() => handleChoose(index + 1)}
            src={`/images/emoji/${index + 1}.gif`}
            className="cursor-pointer w-[20px] lg:w-[50px] 2xl:w-[70px] duration-300 hover-salce-medium"
          />
        );
      }
    );
  };

  return (
    <CustomPopup
      id={POPUP_ID.EMOJI}
      style={{ padding: 0 }}
      closeIcon={false}
      className="no-padding"
    >
      <div className="bg-[#1a3d1b] p-[30px] border-[7px] border-solid border-[#e0b851] rounded-[10px] grid grid-cols-5 gap-[40px]">
        {renderEmoji()}
      </div>
    </CustomPopup>
  );
};

export default EmojiBoard;
