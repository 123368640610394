import { Popover } from "antd";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { PositionStatus } from "share/helperCommon";
import SeatOption from "./components/SeatOption";
import SeatInfoPlayer from "./components/SeatInfoPlayer";
import DialogConfirm from "main/admin/shareComponent/dialog/DialogConfirm";
import { dialogConfirmId } from "../../constant";
import {
  closePopup,
  openPopup,
} from "redux/action/custom-popup/customPopupAction";

const UserJoin = ({
  data,
  sheetNumber,
  avatar = false,
  setOpen,
  gameStart = false,
  socket,
  timeGameStart,
  turn = false,
}) => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.home);
  const {
    mySeat,
    myTurn,
    betReverved,
    showAllCard,
    winnerPlayer,
    reaction,
    bet,
  } = useSelector((state) => state.room);

  const handleTakeSeat = async (type) => {
    debugger;
    if (userInfo?.roleId == 1) {
      switch (type) {
        case "joinSeat": {
          if (mySeat?.HaveClient && gameStart) {
            return;
          }
        }
        case "changeBuyIn": {
        }
        default:
          break;
      }

      if (_.isEmpty(mySeat)) {
        // const response = await fetch("https://api.ipify.org?format=json");
        // const myIP = await response.json();
        socket.send(
          JSON.stringify({
            FunctionCode: "TakeSeat",
            FFunctionCode: "",
            Data: {
              Player: {
                userName: userInfo.userName,
                uid: userInfo.uid,
                PlayerType: userInfo?.roleId,
                TotalAmount: userInfo.accountBalance,
                nickName: userInfo.nickName,
                ipUser: "",
              },
              SeatPosCode: sheetNumber + 1,
            },
          })
        );
      } else {
        if (!data.HaveClient) {
          dispatch(
            openPopup({ id: `${dialogConfirmId.changeSeat}${sheetNumber}` })
          );
          return;
        }
      }
      if (data.HaveClient && data.Player?.uid != userInfo.uid) {
        return;
      }

      setOpen({
        status: true,
        initData: {
          sheetNumber: sheetNumber,
        },
      });
    }
  };

  const handleReverd = () => {
    setOpen({
      status: true,
      initData: {
        sheetNumber: sheetNumber,
      },
    });
  };

  const confirmChangeSeat = () => {
    socket.send(
      JSON.stringify({
        FunctionCode: "ChangeSeat",
        FFunctionCode: "",
        Data: {
          Player: {
            userName: userInfo.userName,
            uid: userInfo.uid,
            PlayerType: userInfo?.roleId,
            TotalAmount: userInfo.accountBalance,
            nickName: userInfo.nickName,
          },
          SeatPosCode: sheetNumber + 1,
        },
      })
    );
    dispatch(closePopup({ id: `${dialogConfirmId.changeSeat}${sheetNumber}` }));
    setOpen({
      status: true,
      initData: {
        sheetNumber: sheetNumber,
      },
    });
  };

  return (
    <>
      {!data.HaveClient && !avatar ? (
        <div
          className="rounded-[25px] border-[4px] border-solid border-[#e0b851] relative w-fit lg:min-w-[120px] 2xl:min-w-[150px] lg:h-[40px] 2xl:h-[50px] bg-[#fffbe0] cursor-pointer"
          onClick={() => handleTakeSeat("joinSeat")}
        >
          <div className="bg-[#f0dc78] lg:w-[50px] lg:h-[50px] 2xl:w-[60px] 2xl:h-[60px] rounded-[50%] absolute bottom-[-3px] left-[-26px] border-[2px] border-solid border-[#e0b851] flex items-center justify-center">
            <img
              src="/images/login/user.png"
              className="lg:w-[25px] lg:h-[25px] 2xl:w-[45px] 2xl:h-[45px]"
            />
          </div>
          <div
            className="flex flex-col items-center justify-center h-full min-w-full box-border lg:pl-[30px] 2xl:pl-[40px] lg:pr-[5px] 2xl:pr-[10px]"
            style={{ transform: "translateY(-3px)" }}
          >
            <h5 className="text-[11px] lg:text-[13px] 2xl:text-[16px] text-[#441d00]">
              Take a seat?
            </h5>
            <img
              src="/images/icon9.png"
              className="lg:w-[13px] lg:h-[18px] 2xl:w-[20px] 2xl:h-[25px] down-arrow"
            />
          </div>
        </div>
      ) : userInfo?.uid?.toString() == data?.Player?.uid &&
        data?.HaveClient &&
        !avatar ? (
        <div
          className={`p-[5px_5px_5px_40px] flex flex-col justify-between ${
            winnerPlayer?.findIndex(
              (wn) =>
                wn.PositionCode == data?.PositionCode &&
                wn.isWinner &&
                showAllCard
            ) > -1 &&
            showAllCard &&
            "bg-[#b10807] h-[100px]"
          } rounded-[15px] relative w-fit overflow-hidden`}
        >
          {winnerPlayer?.findIndex(
            (wn) =>
              wn.PositionCode == data?.PositionCode &&
              wn.isWinner &&
              showAllCard
          ) > -1 &&
            showAllCard && (
              <div className="w-[calc(100%_-_30px)] h-full flex flex-col items-center justify-center ml-[30px]">
                <span className="text-[#efdb78] font-bold lg:text-[12px] 2xl:text-[15px]">
                  WINNER
                </span>
                <span className="text-[13px] lg:text-[18px] 2xl:text-[22px] text-[#efdb78] font-bold w-full text-ellipsis text-nowrap overflow-hidden text-center">
                  + $
                  {winnerPlayer
                    ?.find((wn) => wn.PositionCode == data?.PositionCode)
                    ?.moneyWinner?.toLocaleString()}
                </span>
              </div>
            )}
          <div
            className="rounded-[25px] border-[4px] border-solid border-[#e0b851] relative w-fit lg:min-w-[120px] 2xl:min-w-[150px] lg:h-[40px] 2xl:h-[50px] bg-[#fffbe0] draw"
            onClick={() => {
              ((mySeat.PositionStatus == PositionStatus.TakeSeat &&
                bet.TotalBet == 0) ||
                (mySeat.PositionStatus == PositionStatus.Reserved &&
                  gameStart == false) ||
                showAllCard) &&
                handleReverd();
            }}
          >
            <div className="bg-[#f0dc78] lg:w-[50px] lg:h-[50px] 2xl:w-[60px] 2xl:h-[60px] rounded-[50%] absolute bottom-[-3px] left-[-26px] border-[2px] border-solid border-[#e0b851] flex items-center justify-center">
              <img
                src="/images/login/user.png"
                className="lg:w-[25px] lg:h-[25px] 2xl:w-[45px] 2xl:h-[45px]"
              />
              {winnerPlayer?.findIndex(
                (wn) =>
                  wn.PositionCode == data?.PositionCode &&
                  wn.isWinner &&
                  showAllCard
              ) > -1 &&
                showAllCard && (
                  <img
                    src="/images/login/13.png"
                    className="absolute top-[-28px] left-[-15px] w-[55px] h-[35px]"
                  />
                )}
            </div>
            <div className="flex text-center items-center justify-center h-full min-w-full box-border lg:pl-[30px] 2xl:pl-[40px] lg:pr-[5px] 2xl:pr-[10px]">
              {gameStart || data?.Player?.PriceOnTable > 0 ? (
                <Popover
                  content={<SeatInfoPlayer data={data} />}
                  trigger="hover"
                  className="toolbarPopover"
                >
                  <div className="flex flex-col text-center justify-center h-full min-w-full box-border cursor-pointer">
                    <h5 className="text-[#441d00] text-[11px] lg:text-[13px] 2xl:text-[17px]">
                      YOU
                    </h5>
                  </div>
                </Popover>
              ) : (
                <>
                  <h5
                    className="text-[#441d00] text-[11px] lg:text-[13px] 2xl:text-[17px] mr-[5px]"
                    style={{ transform: "translateY(-1px)" }}
                  >
                    Take Seat
                  </h5>
                  <img
                    src="/images/livegame.png"
                    className="w-[20px] h-[20px]"
                  />
                </>
              )}
            </div>
            {reaction?.find(
              (item) => item.SeatPosCode == data.PositionCode
            ) && (
              <div
                className="absolute bottom-[calc(100%_+_10px)] right-[50%]"
                style={{ transform: "translateX(20%)" }}
              >
                <img
                  src={`/images/emoji/${
                    reaction?.find(
                      (item) => item.SeatPosCode == data.PositionCode
                    )?.EmotionId
                  }.gif`}
                  className="w-[40px] lg:w-[60px] 2xl:w-[100px]"
                />
              </div>
            )}
          </div>
        </div>
      ) : avatar ? (
        <div className="rounded-[25px] border-[4px] border-solid border-[#e0b851] relative lg:min-w-[110px] w-fit 2xl:min-w-[160px] lg:h-[60px] 2xl:h-[70px] bg-[#fffbe0] cursor-pointer">
          <div className="bg-[#f0dc78] lg:w-[60px] lg:h-[60px] 2xl:w-[70px] 2xl:h-[72px] rounded-[50%] absolute bottom-[-3px] left-[-26px] border-[2px] border-solid border-[#e0b851] flex items-center justify-center">
            {myTurn?.status && timeGameStart > 0 && !showAllCard ? (
              <>
                <div
                  className="myTurn lg:w-[40px] lg:h-[40px] 2xl:w-[60px] 2xl:h-[60px]"
                  // style={{
                  //   animationDuration: `${
                  //     timeAction ? timeAction - 1 : 15
                  //   }s steps(800, start)`,
                  // }}
                ></div>
                <div className="lg:text-[16px] 2xl:text-[20px] absolute">
                  {timeGameStart}
                </div>
              </>
            ) : (
              <img
                src="/images/login/user.png"
                className="lg:w-[35px] lg:h-[35px] 2xl:w-[55px] 2xl:h-[55px]"
              />
            )}
          </div>
          <div className="flex flex-col text-center justify-center items-center lg:min-w-[calc(100%_-_20px)] 2xl:min-w-[calc(100%_-_30px)] min-w-full box-border lg:pl-[40px] 2xl:pl-[60px] lg:pr-[5px] 2xl:pr-[10px] h-full">
            <h5
              className="text-[#441d00] text-[10px] lg:text-[13px] 2xl:text-[17px] text-center"
              style={{ transform: "translateX(-10px)" }}
            >
              {userInfo?.nickName}
            </h5>
            <div className="flex flex-col items-start">
              <p className="text-[10px] lg:text-[13px] 2xl:text-[18px] text-[#000]">
                Balance:{" "}
                <span className="text-[#ac2f2e] font-bold">
                  $
                  {mySeat &&
                  mySeat?.Player?.TotalMoney > -1 &&
                  mySeat?.Player?.TotalMoney != null &&
                  mySeat?.Player?.TotalMoney != undefined
                    ? mySeat?.Player?.TotalMoney
                        // mySeat?.Player?.TotalAmount ?
                        //   (betReverved > 0
                        //     ? betReverved
                        // : mySeat?.Player?.TotalAmount -
                        //   mySeat?.Player?.PriceOnTable - mySeat?.TotalBet
                        ?.toLocaleString()
                    : userInfo.accountBalance?.toLocaleString()}
                </span>
              </p>
              <p className="text-[10px] lg:text-[13px] 2xl:text-[18px] text-[#000]">
                Buy-in:{" "}
                <span className="text-[#ac2f2e] font-bold">
                  ${mySeat?.Player?.PriceOnTable?.toLocaleString()}
                </span>
              </p>
            </div>
          </div>
          {!_.isEmpty(data?.turn?.option) && !showAllCard && (
            <div className="absolute top-[calc(100%_+_2px)] right-[5px] w-[75%] bg-[#D9D9D9] rounded-b-[20px] avatar-player-status">
              <div className="w-full h-full flex items-center justify-center">
                <span className="text-[#13563B] font-bold lg:text-[12px] 2xl:text-[15px]">
                  {data?.turn?.option}
                </span>
                <span className="text-[#FFDF00] font-bold ml-[5px] text-hightlight lg:text-[14px] 2xl:text-[15px]">
                  {data?.turn?.BetMoney > 0 &&
                    `$${data?.turn?.BetMoney?.toLocaleString()}`}
                </span>
              </div>
            </div>
          )}
        </div>
      ) : // </Popover>
      !_.isEmpty(data) && !avatar && data.HaveClient ? (
        <div
          className={`p-[5px_5px_5px_40px] flex flex-col justify-between ${
            winnerPlayer?.findIndex(
              (wn) =>
                wn.PositionCode == data?.PositionCode &&
                wn.isWinner &&
                showAllCard
            ) > -1 &&
            showAllCard &&
            "bg-[#b10807] h-[100px] overflow-hidden"
          } rounded-[15px] relative w-fit`}
        >
          {winnerPlayer?.findIndex(
            (wn) =>
              wn.PositionCode == data?.PositionCode &&
              wn.isWinner &&
              showAllCard
          ) > -1 &&
            showAllCard && (
              <div className="w-[calc(100%_-_30px)] h-full flex flex-col items-center justify-center ml-[30px]">
                <span className="text-[#efdb78] font-bold lg:text-[12px] 2xl:text-[15px]">
                  WINNER
                </span>
                <span className="text-[13px] lg:text-[18px] 2xl:text-[22px] text-[#efdb78] font-bold w-full text-ellipsis text-nowrap overflow-hidden text-center">
                  + $
                  {winnerPlayer
                    ?.find((wn) => wn.PositionCode == data?.PositionCode)
                    ?.moneyWinner?.toLocaleString()}
                </span>
              </div>
            )}
          <div className="rounded-[25px] border-[4px] border-solid border-[#e0b851] relative w-fit lg:min-w-[120px] 2xl:min-w-[150px] lg:h-[40px] 2xl:h-[50px] bg-[#fffbe0]">
            <div className="absolute bottom-[calc(100%_+_2px)] right-[5px] w-[80%]">
              {!_.isEmpty(data?.turn?.option) && !showAllCard && (
                <div className="w-full bg-[#D9D9D9] rounded-t-[20px] avatar-player-status-infinite">
                  <div className="w-full h-full flex items-center justify-center">
                    <span className="lg:text-[12px] 2xl:text-[15px] text-[#13563B] font-bold">
                      {data?.turn?.option}
                    </span>
                    <span className="text-[#FFDF00] font-bold ml-[5px] text-hightlight lg:text-[14px] 2xl:text-[15px]">
                      {data?.turn?.BetMoney > 0 &&
                        `$${data?.turn?.BetMoney?.toLocaleString()}`}
                    </span>
                  </div>
                </div>
              )}
            </div>
            {data?.PositionStatus == 7 && (
              <div className="absolute bottom-[calc(100%_+_2px)] right-[5px] w-[80%] bg-[#e7d0d0] rounded-t-[20px] avatar-player-status-infinite">
                <div className="w-full h-full flex items-center justify-center">
                  <span className="text-[#ce2f2f] font-bold">Disconnect</span>
                </div>
              </div>
            )}
            <div className="bg-[#f0dc78] lg:w-[50px] lg:h-[50px] 2xl:w-[60px] 2xl:h-[60px] rounded-[50%] absolute bottom-[-3px] left-[-26px] border-[2px] border-solid border-[#e0b851] flex items-center justify-center">
              {turn && timeGameStart > 0 && !showAllCard ? (
                <>
                  <div className="myTurn lg:w-[50px] lg:h-[50px] 2xl:w-[60px] 2xl:h-[60px]"></div>
                  <div
                    className="lg:text-[16px] 2xl:text-[20px] absolute top-[50%] left-[50%]"
                    style={{ transform: "translate(-50%, -50%)" }}
                  >
                    {timeGameStart}
                  </div>
                </>
              ) : (
                <img
                  src="/images/login/user.png"
                  className="lg:w-[25px] lg:h-[25px] 2xl:w-[45px] 2xl:h-[45px]"
                />
              )}
              {winnerPlayer?.findIndex(
                (wn) =>
                  wn.PositionCode == data?.PositionCode &&
                  wn.isWinner &&
                  showAllCard
              ) > -1 &&
                showAllCard && (
                  <img
                    src="/images/login/13.png"
                    className="absolute top-[-28px] left-[-15px] w-[55px] h-[35px]"
                  />
                )}
            </div>
            <Popover
              content={<SeatInfoPlayer data={data} />}
              // title="Sound setting"
              trigger="click"
              className="toolbarPopover"
            >
              <div className="flex flex-col text-center justify-center h-full min-w-full box-border lg:pl-[30px] 2xl:pl-[40px] lg:pr-[5px] 2xl:pr-[10px] cursor-pointer">
                <h5 className="text-[#441d00] text-[11px] lg:text-[13px] 2xl:text-[16px]">
                  {data?.Player?.nickName}
                </h5>
                <span className="text-[#ac2f2e] text-[13px] lg:text-[15px] 2xl:text-[18px] font-bold">
                  $
                  {data?.Player?.PriceOnTable > 0
                    ? data?.Player?.PriceOnTable?.toLocaleString()
                    : 0}
                </span>
              </div>
            </Popover>
            {reaction?.find(
              (item) => item.SeatPosCode == data.PositionCode
            ) && (
              <div
                className="absolute bottom-[calc(100%_+_10px)] right-[50%]"
                style={{ transform: "translateX(20%)" }}
              >
                <img
                  src={`/images/emoji/${
                    reaction?.find(
                      (item) => item.SeatPosCode == data.PositionCode
                    )?.EmotionId
                  }.gif`}
                  className="w-[40px] lg:w-[60px] 2xl:w-[100px]"
                />
              </div>
            )}
            {/* <div className="absolute bottom-[calc(100%_+_10px)] right-[50%]" style={{transform: 'translateX(20%)'}}>
            <img src="/images/emoji/7.gif" className="w-[40px] lg:w-[60px] 2xl:w-[100px]"/>
          </div> */}
          </div>
        </div>
      ) : (
        <></>
      )}

      <DialogConfirm
        id={`${dialogConfirmId.changeSeat}${sheetNumber}`}
        render={
          <div className="flex flex-col justify-between p-[10px_20px] 2xl:min-w-[200px] 2xl:min-h-[100px]">
            <div className="flex justify-between dialog-header">
              <h5 className="lg:text-[20px] 2xl:text-[23px]">Confirm</h5>
              {/* <button className="outline-none border-none bg-transparent">&times;</button> */}
            </div>
            <div className="dialog-body my-[30px]">
              <p className="lg:text-[13px] 2xl:text-[17px]">
                Are you sure to change seat?
              </p>
            </div>
            <div className="dialog-footer flex items-center justify-end">
              <button
                className="lg:p-[10px_7px] 2xl:p-[10px] min-w-[80px] rounded-[20px] border-none outline-none bg-[#C83737] text-[#fff] flex items-center justify-center"
                onClick={() => {
                  dispatch(
                    closePopup({
                      id: `${dialogConfirmId.changeSeat}${sheetNumber}`,
                    })
                  );
                }}
              >
                Cancel
              </button>
              <button
                className="lg:p-[10px_7px] 2xl:p-[10px] min-w-[80px] rounded-[20px] border-none outline-none bg-[#13563B] text-[#fff] flex items-center justify-center ml-[10px]"
                onClick={confirmChangeSeat}
              >
                OK
              </button>
            </div>
          </div>
        }
      />
    </>
  );
};

export default UserJoin;
